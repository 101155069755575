import { computed, defineComponent, onMounted, ref } from "vue";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons-vue";
import { Toast } from "vant";
import { sendLoginVerifyCodeOld, externalLogin } from "@/apis/index";
import { isMobile, TokenKey, setCookie2, ShardKey, H3EngineCode, } from "../../utils";
import { message } from "ant-design-vue";
const MobileRegexp = /((\+?86-?)?1[3456789]\d{9})$/;
export default defineComponent({
    name: "LoginMobile",
    components: {
        CloseCircleFilled,
        CheckCircleFilled,
    },
    methods: {
        async getVerifyCode() {
            if (this.timer)
                return;
            const isOk = this.checkMobile();
            if (!isOk)
                return;
            const res = await sendLoginVerifyCodeOld({ mobile: this.mobile });
            const { errcode, errmsg } = res;
            if (errcode === 0) {
                this.timer = setInterval(() => {
                    if (this.seconds === 0) {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.seconds = 60;
                    }
                    else {
                        this.seconds--;
                    }
                }, 1000);
                this.verifyError = false;
                this.verifySend = true;
            }
            else {
                this.toast(errmsg, "error");
            }
        },
        checkMobile() {
            if (this.mobile) {
                const isOk = MobileRegexp.test(this.mobile);
                if (!isOk) {
                    if (isMobile) {
                        this.toast("请输入正确的手机号");
                    }
                    else {
                        this.mobileErrorText = "请输入正确的手机号";
                        this.mobileError = true;
                    }
                    return false;
                }
                this.mobileError = false;
                return true;
            }
            else {
                if (isMobile) {
                    this.toast("请输入正确的手机号");
                }
                else {
                    this.mobileError = true;
                    this.mobileErrorText = "请输入手机号";
                }
                return false;
            }
        },
        mobileChange() {
            this.mobile = this.mobile.replace(/[^0-9]*$/, "").substring(0, 11);
        },
        mobileBlur() {
            setTimeout(() => {
                this.isMobileFocus = false;
            }, 0);
        },
        verifyChange() {
            this.verify = this.verify.replace(/[^0-9]*$/, "").substring(0, 6);
        },
        verifyBlur() {
            setTimeout(() => {
                this.isVerifyFocus = false;
            }, 0);
        },
        toast(content, type) {
            if (isMobile) {
                Toast(content);
            }
            else {
                message.open({
                    content: content,
                    type: type || "warning",
                    duration: 3,
                });
            }
        },
        async login() {
            if (this.active) {
                const { engineCode, redirect } = this.$route.query;
                const res = await externalLogin({
                    mobile: this.mobile,
                    verifycode: this.verify,
                    enginecode: engineCode
                });
                const { errcode, errmsg } = res;
                if (errcode === 0) {
                    if (redirect) {
                        const { result: { enginecode, shardKey, token }, } = res;
                        setCookie2(H3EngineCode, enginecode);
                        setCookie2(ShardKey, shardKey);
                        setCookie2(TokenKey, token);
                    }
                    window.location.replace(redirect);
                }
                else {
                    if (this.isMobile) {
                        this.toast(`${errmsg},请联系系统管理员`, "error");
                    }
                    else {
                        if (errcode === 201101) {
                            this.verifyError = true;
                            this.verifySend = false;
                            this.verifyErrorText = errmsg;
                        }
                        else {
                            this.toast(errmsg, "error");
                        }
                    }
                }
            }
        },
        goHome() {
            window.location.href = "https://h3yun.com/#/home";
        },
    },
    setup() {
        const mobile = ref("");
        const verify = ref("");
        const seconds = ref(60);
        const timer = ref(null);
        const visible = ref(false);
        const text = ref("");
        const toastText = ref("");
        const showToast = ref(false);
        const timeOut = ref(null);
        const company = ref("");
        const clientHeight = ref(0);
        const isMobileFocus = ref(false);
        const isVerifyFocus = ref(false);
        const verifyError = ref(false);
        const verifySend = ref(false);
        const mobileError = ref(false);
        const mobileErrorText = ref("请输入手机号");
        const verifyErrorText = ref("验证码错误");
        const active = computed(() => {
            return mobile.value && verify.value;
        });
        const pcHeader = ref("");
        onMounted(() => {
            company.value = sessionStorage.getItem("company");
            clientHeight.value = window.innerHeight;
            const title = sessionStorage.getItem("login_title");
            document.title = title || "氚云";
            pcHeader.value = title || "氚云";
        });
        return {
            mobile,
            verify,
            seconds,
            timer,
            visible,
            text,
            showToast,
            toastText,
            timeOut,
            active,
            isMobile,
            company,
            clientHeight,
            pcHeader,
            isMobileFocus,
            isVerifyFocus,
            verifyError,
            verifySend,
            mobileError,
            mobileErrorText,
            verifyErrorText,
        };
    },
});
